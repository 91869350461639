<template>
  <b-modal
    ref="modal-dealsheet-excel"
    hide-footer
    :title="titleExcel"
    id="modal-dealsheet-excel"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <b-form-group label="Danh sách cần xuất:">
          <b-form-radio-group v-model="selectedListExcel">
            <b-form-radio value="all">Tất cả các trang</b-form-radio>
            <b-form-radio value="filter">Theo bộ lọc</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-row v-if="selectedListExcel === 'all'">
          <b-col md="4">
            <b-form-group>
              <label>Từ ngày:</label>
              <date-picker
                placeholder="Từ ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.fromDate"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Đến ngày:</label>
              <date-picker
                placeholder="Tới ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.toDate"
              ></date-picker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay
      :show="isDownloading"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
      @hidden="$refs.button.focus()"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            variant="light"
            small
            type="grow"
          ></b-spinner>
        </div>
      </template>
      <!-- <div >
        <b-spinner label="Loading..."></b-spinner>
      </div> -->
      <b-button
        variant="primary"
        size="sm"
        @click="exportToExcelStream"
      >
        <b-icon
          class="mr-1"
          icon="download"
          aria-hidden="true"
        ></b-icon>
        <strong>Xuất Excel</strong>
      </b-button>
    </b-overlay>

    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="hideModal"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { checkPermissions } from '../../../utils/saveDataToLocal';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { cloneDeep } from 'lodash';
import { makeToastFaile, makeToastSuccess } from '../../../utils/common';
import fileDownload from '../../../utils/file-download';
import axios from 'axios';
import { BASE_URL } from './../../../utils/constants';
import timeUtils from '@/utils/date';
import moment from 'moment';
export default {
  mixins: [validationMixin],
  props: ['apiParams'],
  data() {
    return {
      dpConfigs: timeUtils.DP_CONFIG,
      selectedListExcel: 'all',
      titleExcel: 'Xuất excel danh sách dealsheet',
      isDownloading: false,
    };
  },
  components: {},
  computed: {},
  created() {},
  validations: {},
  methods: {
    checkPermissions,
    hideModal: function () {
      this.apiParams.fromDate = '';
      this.apiParams.toDate = '';
      this.$bvModal.hide('modal-dealsheet-excel');
    },
    async exportToExcelStream() {
      this.isDownloading = true;
      const params = this.getParamsSelected();
      if (!params) return;
      try {
        const response = await axios.get(
          `${BASE_URL}v2/deal-sheets-export-excel`,
          {
            params: {
              ...params,
            },
            responseType: 'blob',
          },
        );
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
        this.$bvModal.hide('modal-dealsheet-excel');
        makeToastSuccess('Tải xuống thành công!');
        this.isDownloading = false;
      } catch (err) {
        this.isDownloading = false;
        const { message } = err.response?.data || 'Lỗi';
        makeToastFaile(message);
      }
    },
    getParamsSelected: function () {
      let params = {};

      if (this.selectedListExcel === 'all') {
        let { toDate, fromDate } = cloneDeep(this.apiParams);
        if (!toDate || !fromDate) {
          makeToastFaile('Vui lòng chọn khoảng thời gian trong 2 tháng');
          this.isDownloading = false;
          return;
        }
        const startMoment = moment(fromDate, 'DD/MM/YYYY');
        const endMoment = moment(toDate, 'DD/MM/YYYY');
        const diffMonths = endMoment.diff(startMoment, 'months');
        const diffDate = endMoment.diff(startMoment, 'days');
        if (diffMonths > 2 || diffDate > 61) {
          makeToastFaile('Vui lòng chọn khoảng thời gian trong vòng 2 tháng!');
          this.isDownloading = false;
          return null;
        }
        params = {
          fromDate,
          toDate,
        };
      } else {
        params = cloneDeep(this.apiParams);
      }
      return params;
    },
  },
};
</script>